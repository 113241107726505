import BaseSearchFilter from './BaseSearchFilter'

class ProductFilter extends BaseSearchFilter {
  /** @type {string} */
  #packType = 'all'

  /** @type {string} */
  #identifiersType = 'KEYWORDS'

  constructor() {
    super()
    this.#packType = this.packType
    this.#identifiersType = this.identifiersType
  }

  setDefaultFilters() {
    this.accountNo = null
    this.companyId = 0
    this.storeId = 0
    this.warehouseId = 0
    this.packType = 'all'
    this.identifiersType = 'KEYWORDS'
    this.beginDate = null
    this.endDate = null
    this.tenantType = 'SERVING'
    this.q = ''
  }

  /**
   * The complete UploadedBatchFilter get filters.
   * @typedef {Object} filters
   * @property {number} accountNo - Account Number.
   * @property {number} companyId - Company Id.
   * @property {number} storeId - Store Id.
   * @property {number} warehouseId - Store Id.
   * @property {any} beginDate - Begin date.
   * @property {any} endDate - End date.
   * @property {string} tenantType - Tenant Type.
   * @property {string} q - Query.
   * @property {string} packType - Status.
   * @property {string} identifiersType - identifiersType.
   */

  /** @returns {filters} {@link filters} objects */
  getFilters() {
    const filter = {
      accountNo: this.accountNo,
      companyId: this.companyId,
      storeId: this.storeId,
      warehouseId: this.warehouseId,
      packType: this.packType,
      identifiersType: this.identifiersType,
      beginDate: this.beginDate,
      endDate: this.endDate,
      tenantType: this.tenantType,
      q: this.q,
    }
    return filter
  }

  /** @returns {string} */
  get packType() {
    return this.#packType
  }

  /** @param {string} value */
  set packType(value) {
    this.#packType = value
  }

  /** @returns {string} */
  get identifiersType() {
    return this.#identifiersType
  }

  /** @param {string} value */
  set identifiersType(value) {
    this.#identifiersType = value
  }
}

export default new ProductFilter()
