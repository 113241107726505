<template>
  <div>
    <dx-util-popup
      ref="popupAddItemBatchRef"
      :show-close-button="true"
      :drag-enabled="false"
      :close-on-outside-click="false"
      :show-title="true"
      :full-screen="false"
      title="Add Item"
      @hidden="onHidden"
    >
      <dx-util-position at="center" my="center" />
        <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="cancelButtonOptions" />
        <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="saveButtonOptions" />
      <form method="post" @submit="handleSubmit($event)">
        <dx-util-form
          ref="itemFormRef"
          :form-data="itemFormData"
          :col-count="1"
          :show-colon-after-label="true"
          label-location="top"
          width=""
          validation-group="itemFormData"
        >
          <template #validation-summary-template="{}">
            <ul class="list-unstyled">
              <li v-for="(error, index) in validationErrors" :key="index" class="text-danger">
                {{ error }}
              </li>
            </ul>
          </template>
          <dx-util-item :visible="!isValid" template="validation-summary-template" />
          <dx-util-item data-field="ProductTitle" :label="{text: 'Title'}" editor-type="dxTextBox">
            <dx-util-required-rule message="Title is required" />
          </dx-util-item>
          <dx-util-group-item :col-count="4">
            <dx-util-item data-field="ManufactureOrSupplier" :col-span="2" :label="{text: 'Supplier'}" editor-type="dxTextBox">
              <dx-util-required-rule message="Supplier is required" />
            </dx-util-item>
            <dx-util-item data-field="ShipmentDate" :label="{text: 'Shipment Date'}" editor-type="dxDateBox">
                <dx-util-required-rule message="Shipment Date is required" />
            </dx-util-item>
            <dx-util-item data-field="ExpirationDate" :label="{text: 'Expiration Date'}" editor-type="dxDateBox" />
          </dx-util-group-item>
          <dx-util-group-item :col-count="4">
            <dx-util-item data-field="TrackingNumber" :label="{text: 'Tracking Number'}" editor-type="dxTextBox">
              <!-- <dx-util-async-rule :validation-callback="checkEitherOrderOrTrackingNumber" :reevaluate="true" message="Order Number or Tracking Number is required" /> -->
            </dx-util-item>
            <dx-util-item data-field="OrderNumber" :label="{text: 'Order Number'}" editor-type="dxTextBox">
              <!-- <dx-util-async-rule :validation-callback="checkEitherOrderOrTrackingNumber" message="Order Number or Tracking Number is required" /> -->
            </dx-util-item>
            <dx-util-item data-field="OrderNumberSold" :label="{text: 'Order Number Sold'}" editor-type="dxTextBox" />
            <dx-util-item data-field="PalletId" :label="{text: 'Pallet Id'}" editor-type="dxTextBox" />
          </dx-util-group-item>
            <dx-util-group-item :col-count="4">
            <dx-util-item data-field="ASIN" :label="{text: 'ASIN'}" editor-type="dxTextBox" :editor-options="asinOptions">
              <!-- <dx-util-async-rule :validation-callback="checkEitherASINOrMSKU" message="ASIN or MSKU is required" /> -->
            </dx-util-item>
            <dx-util-item data-field="UPC" :label="{text: 'UPC'}" editor-type="dxTextBox" />
            <dx-util-item data-field="MSKU" :label="{text: 'MSKU'}" editor-type="dxTextBox">
              <!-- <dx-util-async-rule :validation-callback="checkEitherASINOrMSKU" message="ASIN or MSKU is required" /> -->
            </dx-util-item>
            <dx-util-item data-field="FNSKU" :label="{text: 'FNSKU'}" editor-type="dxTextBox" />
          </dx-util-group-item>
          <dx-util-group-item :col-count="4">
            <dx-util-item data-field="SerialNo" :label="{text: 'Serial No'}" editor-type="dxTextBox" />
            <dx-util-item data-field="Fragile" editor-type="dxSelectBox" :editor-options="selectYesNoOptions" />
            <dx-util-item data-field="MultiPack" editor-type="dxSelectBox" :editor-options="selectYesNoOptions" />
            <dx-util-item data-field="Bundle" editor-type="dxSelectBox" :editor-options="selectYesNoOptions" />
          </dx-util-group-item>
          <dx-util-group-item :col-count="4">
            <dx-util-item data-field="CountPerBundle" :label="{text: 'Count/Bundle'}" editor-type="dxNumberBox" :editor-options="numberOptions" />
            <dx-util-item data-field="NumberOfUnits" :label="{text: 'Quantity'}" editor-type="dxNumberBox" :editor-options="numberOptions">
              <dx-util-range-rule :min="1" message="Quantity should be greater than 0" />
              <dx-util-required-rule message="Quantity is required" />
            </dx-util-item>
            <dx-util-item data-field="Cost" editor-type="dxNumberBox" :editor-options="currencyOptions">
              <dx-util-required-rule message="Cost is required" />
              <dx-util-range-rule :min="0.1" message="Cost should be greater than 0" />
            </dx-util-item>
            <dx-util-item data-field="SalePrice" editor-type="dxNumberBox" :editor-options="currencyOptions">
              <dx-util-required-rule message="Sale Price is required" />
              <dx-util-range-rule :min="0.1" message="Sale price should be greater than 0" />
            </dx-util-item>
          </dx-util-group-item>
          <dx-util-group-item :col-count="2">
            <dx-util-item data-field="Action" :label="{text: 'Item Action'}" editor-type="dxSelectBox" :editor-options="actionOptions">
              <dx-util-required-rule message="Item Action is required" />
            </dx-util-item>
            <dx-util-item data-field="Condition" :label="{text: 'Condition'}" editor-type="dxSelectBox" :editor-options="conditionOptions">
              <dx-util-required-rule message="Condition is required" />
            </dx-util-item>
          </dx-util-group-item>
          <dx-util-group-item :col-count="3">
            <dx-util-item data-field="Tags" :label="{text: 'Tags'}" editor-type="dxTextBox" />
            <dx-util-item data-field="PurchasedBy" :label="{text: 'Purchased By'}" editor-type="dxTextBox" />
            <dx-util-item data-field="Source" :label="{text: 'Source'}" editor-type="dxTextBox" />
          </dx-util-group-item>
          <dx-util-item data-field="Notes" editor-type="dxTextArea" :editor-options="notesOptions" />
        </dx-util-form>
      </form>
    </dx-util-popup>
  </div>

</template>

<script>
/* eslint-disable no-unused-vars */
import { emitter } from '@/libs/bus'
import useCurrentUser from '@/libs/app/current-user'
import shipService from '@/http/requests/ship/shipService'
import { getConditionTypeEnumList } from '@/enums/conditionTypeEnum'
import { getYesNoEnumList } from '@/enums/yes-no.enum'
import {
  isRequiredAny,
  isValidFnsku,
  isValidMsku,
  isValidAsin,
  isCountNumberOfUnits,
  isCountPerBundle,
  isBundleOrMultipack,
} from '@/views/apps/ship/validationItem'

export default {
  emits: ['create-or-update-item', 'cancel-create-or-update-item'],
  props: {
  },
  setup() {
    const { userFullname } = useCurrentUser()
    return {
      userFullname,
    }
  },
  data() {
    return {
      batchId: null,
      itemFormData: {},
      actions: [],
      conditions: [...getConditionTypeEnumList()],
      yesNo: getYesNoEnumList(),
      defaultItemFormData: {},
      currencyOptions: {
        format: '$ #,##0.##',
      },
      numberOptions: {
        min: 0,
        step: 1,
        showSpinButtons: true,
      },
      isValid: true,
      validationErrors: [],
      popupIns: null,
      isInitial: false,
      // asinPattern: /^(B[A-Z0-9]{9})|([0-9]{9}[0-9Xx])$/,
    }
  },
  computed: {
    asinOptions() {
      return {
        readOnly: this.itemFormData.Action === this.$enums.ItemActionTypeEnum.fba.value && !this.isInitial,
      }
    },
    selectYesNoOptions() {
      return {
        dataSource: this.yesNo,
        valueExpr: 'value',
        displayExpr: 'text',
      }
    },
    notesOptions() {
      return {
        inputAttr: { rows: 5 },
      }
    },
    actionOptions() {
      return {
        dataSource: this.actions,
        valueExpr: 'value',
        displayExpr: 'text',
      }
    },
    conditionOptions() {
      return {
        dataSource: this.conditions,
        valueExpr: 'value',
        displayExpr: 'text',
      }
    },
    saveButtonOptions() {
      return {
        text: 'Save',
        type: 'success',
        useSubmitBehavior: true,
        onClick: e => {
          this.handleSubmit(e)
        },
      }
    },
    cancelButtonOptions() {
      return {
        text: 'Cancel',
        type: 'danger',
        onClick: () => {
          this.closePopupAddItemBatch()
        },
      }
    },
  },
  created() {
    emitter.on('on-open-add-item-batch', payload => {
      this.initPage(payload)
    })
  },
  mounted() {
    Object.keys(this.$enums.ItemActionTypeEnum).map(key => this.actions.push({
      value: this.$enums.ItemActionTypeEnum[key].value,
      text: this.$enums.ItemActionTypeEnum[key].text,
      disabled: false,
    }))
    Object.assign(this.defaultItemFormData, this.itemFormData)
    this.popupIns = this.$refs.popupAddItemBatchRef.instance
  },
  methods: {
    // checkEitherOrderOrTrackingNumber() {
    //   return new Promise(resolve => {
    //     setTimeout(() => {
    //       const hasTrackingNumber = this.itemFormData.TrackingNumber !== ''
    //       const hasOrderNumber = this.itemFormData.OrderNumber !== ''
    //       const isValid = hasTrackingNumber || hasOrderNumber
    //       resolve(isValid)
    //     }, 300)
    //   })
    // },
    // checkEitherASINOrMSKU() {
    //   return new Promise(resolve => {
    //     setTimeout(() => {
    //       const hasASIN = this.itemFormData.ASIN !== ''
    //       const isValidASIN = isValidAsin(this.itemFormData.ASIN)
    //       const hasMSKU = this.itemFormData.MSKU !== ''
    //       const isValid = (hasASIN && isValidASIN) || (hasMSKU && this.itemFormData.Action !== 'FBA')
    //       resolve(isValid)
    //     }, 300)
    //   })
    // },
    isValidUpc(value) {
      if (value !== '' && value !== null && typeof value !== 'undefined') {
        const regex = /^[0-9]{12,13}$/gm
        return regex.test(value)
      }
      return true
    },
    initPage(payload) {
      const { data, batchId } = payload
      if (!data) {
        this.isInitial = true
        this.batchId = batchId
        this.itemFormData = {
          id: null,
          batchId: batchId || parseInt(this.$route.params.batchId, 10),
          ProductTitle: '',
          ManufactureOrSupplier: '',
          ProductGroup: '',
          TrackingNumber: '',
          OrderNumber: '',
          ShipmentDate: new Date(),
          UPC: '',
          ASIN: '',
          MSKU: '',
          FNSKU: '',
          Fragile: 'NO',
          MultiPack: 'NO',
          Bundle: 'NO',
          SerialNo: '',
          CountPerBundle: 0,
          NumberOfUnits: 0,
          Cost: 0,
          SalePrice: 0,
          Action: 'FBA',
          Condition: 'NEW',
          PurchasedBy: this.userFullname,
          Notes: '',
        }
        this.popupIns.show()
      } else if (data && !data.id) {
        this.itemFormData = {
          id: null,
          batchId: batchId || parseInt(this.$route.params.batchId, 10),
          TrackingNumber: data.TrackingNumber,
          OrderNumber: data.OrderNumber,
          OrderNumberSold: data.OrderNumberSold,
          PalletId: data.PalletId,
          ShipmentDate: data.ShipmentDate ? data.ShipmentDate : new Date(),
          ExpirationDate: data.ExpirationDate,
          ASIN: data.ASIN,
          MSKU: data.MSKU,
          UPC: data.UPC,
          FNSKU: data.FNSKU,
          SerialNo: data.SerialNo,
          ProductTitle: data.ProductTitle,
          ManufactureOrSupplier: data.ManufactureOrSupplier,
          NumberOfUnits: data.NumberOfUnits,
          Condition: data.Condition,
          Cost: data.Cost,
          SalePrice: data.SalePrice,
          Fragile: data.Fragile,
          MultiPack: data.MultiPack,
          Bundle: data.Bundle,
          CountPerBundle: data.CountPerBundle,
          Action: data.Action,
          PurchasedBy: this.userFullname,
          Notes: data.Notes,
        }
        this.popupIns.show()
      } else {
        this.getSelectedShipmentItem(data.id)
      }
    },
    getSelectedShipmentItem(id) {
      shipService.fetchItemById(id).then(result => {
        const { data } = result
        this.itemFormData = { ...data }
      }).then(() => {
        this.popupIns.show()
      })
    },
    handleSubmit() {
      const form = this.$refs.itemFormRef.instance
      const validate = form.validate()
      let isValid = true
      const validationErrors = []
      const item = { ...this.itemFormData }
      if (item.Action === this.$enums.ItemActionTypeEnum.fba.value && !isValidAsin(item.ASIN)) {
        isValid = false
        validationErrors.push('Please enter a valid ASIN value, because item action is FBA')
      }
      if (!isValidMsku(item.MSKU)) {
        isValid = false
        validationErrors.push('Please enter a valid MSKU value.')
      }
      if (!isValidFnsku(item.FNSKU)) {
        isValid = false
        validationErrors.push('Please enter a valid FNSKU value.')
      }
      if (!this.isValidUpc(item.UPC)) {
        isValid = false
        validationErrors.push('Please enter a valid UPC value.')
      }
      if (!isRequiredAny(item.TrackingNumber, item.OrderNumber)) {
        isValid = false
        validationErrors.push('Please enter Tracking Number or Order Number value.')
      }
      if (!isRequiredAny(item.ASIN, item.MSKU)) {
        isValid = false
        validationErrors.push('Please enter ASIN or MSKU value.')
      }
      if (!isCountNumberOfUnits(item.Bundle, item.MultiPack, item.NumberOfUnits)) {
        isValid = false
        validationErrors.push('Number of Units must be greater than 0, used in this Bundle/Multipack.')
      }
      if (!isCountPerBundle(item.Bundle, item.MultiPack, item.CountPerBundle)) {
        isValid = false
        validationErrors.push('Please enter a Count Per Bundle value for the number of units to be used in this Bundle/Multipack. Please make sure that you enter number of sellable items in Number of Units column')
      }
      if (!isBundleOrMultipack(item.Bundle, item.MultiPack)) {
        isValid = false
        validationErrors.push('Please make sure that either of multipack or bundle can be YES.')
      }
      if (item.NumberOfUnits <= 0) {
        isValid = false
        validationErrors.push('Quantity (Number of Units) must be greater than 0.')
      }
      if (item.SalePrice <= 0) {
        isValid = false
        validationErrors.push('Sale Price must be greater than 0.')
      }
      if (item.Cost <= 0) {
        isValid = false
        validationErrors.push('Cost must be greater than 0.')
      }
      this.isValid = isValid
      this.validationErrors = [...validationErrors]
      if (validate.isValid && isValid) {
        const id = item.id
        if (id) {
          shipService.updateItem(item).then(() => {
            this.$emit('on-close-add-item-batch', item)
            this.popupIns.hide()
            Object.assign(this.itemFormData, this.defaultItemFormData)
          })
        } else {
          shipService.createItem(item).then(() => {
            this.$emit('on-close-add-item-batch')
            this.popupIns.hide()
            Object.assign(this.itemFormData, this.defaultItemFormData)
          })
        }
      }
    },
    closePopupAddItemBatch() {
      this.popupIns.hide()
      this.$emit('on-close-add-item-batch')
    },
    onHidden() {
      this.validationErrors = []
    },
  },
}
</script>

<style>

</style>
