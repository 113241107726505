import find from 'lodash/find'
/**
 * Enum for Item Action Type Status.
 * @readonly
 * @enum {{key: string, value: string}}
 */

const identifiersTypeEnum = Object.freeze({
  ASIN: { key: 'ASIN', value: 'ASIN', text: 'Amazon Standard Identification Number' },
  EAN: { key: 'EAN', value: 'EAN', text: 'European Article Number' },
  GTIN: { key: 'GTIN', value: 'GTIN', text: 'Global Trade Item Number' },
  ISBN: { key: 'ISBN', value: 'ISBN', text: 'International Standard Book Number' },
  JAN: { key: 'JAN', value: 'JAN', text: 'Japanese Article Number' },
  MINSAN: { key: 'MINSAN', value: 'MINSAN', text: 'Minsan Code' },
  SKU: { key: 'SKU', value: 'SKU', text: 'Stock Keeping Unit, a seller-specified identifier for an Amazon listing' },
  UPC: { key: 'UPC', value: 'UPC', text: 'Universal Product Code' },
  KEYWORDS: { key: 'KEYWORDS', value: 'KEYWORDS', text: 'Keywords' },
})

function getIdentifiersTypeEnum(predicate, predicateKey = 'key') {
  const result = find(identifiersTypeEnum, { [predicateKey]: predicate })
  return result || { key: 'None', value: null, text: 'None' }
}

function getIdentifiersTypeEnumList() {
  const options = []
  Object
    .keys(identifiersTypeEnum)
    .map(key => options.push(
      {
        value: identifiersTypeEnum[key].value,
        text: identifiersTypeEnum[key].text,
      },
    ))
  return options
}

export default identifiersTypeEnum

export {
  getIdentifiersTypeEnum,
  getIdentifiersTypeEnumList,
}
