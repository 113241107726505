<template>
    <!--Begin:: Breadcrumb-->
    <div>
      <div class="col-md-12 px-0">
        <dx-data-grid
          id="shipmentItemsGrid"
          ref="shipmentItemsGridRef"
          :data-source="dataSource"
          :height="customTableHeight"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :show-borders="true"
          :row-alternation-enabled="false"
          :hover-state-enabled="true"
          :customize-columns="customizeColumns"
          @initialized="onInitialized"
          @cell-dbl-click="onCellDblClick"
          @toolbar-preparing="onToolbarPreparing($event)"
          @option-changed="onOptionChanged"
          @editor-preparing="onEditorPreparing"
        >
          <!--region    ::DataGird base components -->
            <dx-sorting mode="single" />
            <dx-scrolling mode="virtual" row-rendering-mode="virtual" />
            <dx-remote-operations :paging="true" :filtering="false" :sorting="true" :summary="false" />
            <dx-column-chooser :enabled="true" mode="select" title="Column Chooser" />
            <dx-search-panel :visible="true" :width="200" placeholder="Search..." />
            <dx-paging :page-size="pagerOptions.pageSize" />
            <dx-pager
              :allowed-page-sizes="pagerOptions.allowedPageSizes"
              :show-info="pagerOptions.showInfo"
              :show-navigation-buttons="pagerOptions.showNavigationButtons"
              :show-page-size-selector="pagerOptions.showPageSizeSelector"
              :visible="pagerOptions.visible"
            />
          <!--endregion ::DataGird base components -->
          <dx-column data-field="smallImage" cell-template="imageCellTemplate" caption="Image" alignment="center" :allow-sorting="false" />
          <template #imageCellTemplate="{data}">
            <b-img-lazy :rounded="true" width="72px" height="72px" :src="data.value || defaultImg" />
          </template>
          <dx-column data-field="title" :width="300" cell-template="titleCellTemplate" />
          <template #titleCellTemplate="{data}">
            <div style="width:100%; overflow-wrap:break-word; white-space:normal; height:100%">
              {{ data.value }}
            </div>
          </template>
          <dx-column data-field="tracking" />
          <dx-column data-field="orderNumber" />
          <dx-column data-field="orderNumberSold" caption="Order # Sold" />
          <dx-column data-field="palletId" caption="Pallet Id" />
          <dx-column data-field="shipmentDate" caption="Ship Date" data-type="date" :allow-filtering="false" />
          <dx-column data-field="expirationDate" caption="Exp Date" data-type="date" :allow-filtering="false" />
          <dx-column data-field="asin" caption="ASIN" :width="120" cell-template="asinCellTemplate" />
          <template #asinCellTemplate="{data}">
            <a target="_blank" :href="`https://www.amazon.com/gp/product/${data.value}`">
              {{ data.value }}
            </a>
          </template>
          <dx-column data-field="msku" caption="MSKU" width="120" />
          <dx-column data-field="upc" caption="UPC" width="120" />
          <dx-column data-field="fnsku" caption="FNSKU" width="110" />
          <dx-column data-field="serialNo" width="80" />
          <dx-column data-field="supplier" />
          <dx-column data-field="quantity" caption="Expected" :allow-filtering="false" />
          <dx-column data-field="itemCondition" caption="Cond" :allow-filtering="false" />
          <dx-column data-field="itemCost" caption="Cost" :format="{ type: 'currency', precision: 2 }" :allow-filtering="false" />
          <dx-column data-field="salePrice" :format="{ type: 'currency', precision: 2 }" :allow-filtering="false" />
          <dx-column data-field="fragile" :allow-filtering="false" />
          <dx-column data-field="packType" caption="Packing" width="70" cell-template="cellPackType" />
          <template #cellPackType="{ data }">
            <span v-if="data.value === 'individual'" v-b-tooltip.d800.hover.left.v-secondary="'Individual pack'" class="d-block badge badge-light-secondary">
              Single
            </span>
            <span v-else-if="data.value === 'bundle'" class="d-block badge badge-light-secondary">
              Bundle
            </span>
            <span v-else-if="data.value === 'multi_pack'" v-b-tooltip.d800.hover.left.v-secondary="'Multi Pack'" class="d-block badge badge-light-secondary">
              MP
            </span>
          </template>

          <dx-column
            data-field="countPerBundle"
            caption="C/B,MP"
            cell-template="CountInBundleTemplate"
            header-cell-template="header-count-per-bundle"
            :allow-filtering="false"
            alignment="center"
          />
          <template #header-count-per-bundle="{ data }">
            <div v-b-tooltip.bottom title="Count used per Bundle/MultiPack">
              {{ data.column.caption }}
            </div>
          </template>
          <dx-column
            data-field="countInBundle"
            caption="InBundle"
            cell-template="CountInBundleTemplate"
            header-cell-template="header-count-in-bundle"
            :allow-filtering="false"
            alignment="center"
          />
          <template #CountInBundleTemplate="{ data }">
            <span
              v-if="data.value > 0"
              :class="`${resolveBundleMultiPackVariant(data.value)}`"
              class="d-block badge text-capitalize badge-pill"
            >
              {{ data.value }}
            </span>
          </template>
          <template #header-count-in-bundle="{ data }">
            <div v-b-tooltip.bottom title="Total count used in Bundle/MultiPack">
              {{ data.column.caption }}
            </div>
          </template>
          <dx-column data-field="notes" />
          <dx-column data-field="Tags" />
          <dx-column data-field="PurchasedBy" />
          <dx-column data-field="Source" />
          <!-- TODO: dx button conversion -->
          <dx-column type="buttons" caption="Actions">
            <dx-button>
              <template #default="{data}">
                <b-button
                  size="sm"
                  variant="flat-warning"
                  class="btn-action-xs"
                  @click="editSelectedItem(data)"
                >
                  <b-icon scale="1" icon="pencil-square" aria-label="Edit" />
                </b-button>
              </template>
            </dx-button>
            <dx-button>
              <template #default="{data}">
                <b-button
                  size="sm"
                  variant="flat-danger"
                  class="btn-action-xs"
                  @click="deleteItem(data)"
                >
                  <b-icon scale="1" icon="trash-fill" aria-label="Delete" />
                </b-button>
              </template>
            </dx-button>
          </dx-column>
          <template #filterToolbar>
            <div class="d-flex flex-row align-items-center">
              <h5 class="my-0">
                {{ batchName }} Batch Item List
              </h5>
            </div>
          </template>
        </dx-data-grid>
      </div>
      <div class="col-md-12 px-0">
        <light-box
          :items="images"
          :index="index"
          :effect="'fade'"
          @close="index = null"
        />
      </div>
    </div>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import { emitter } from '@/libs/bus'
import shipService from '@/http/requests/ship/shipService'
import CoolLightBox from 'vue-cool-lightbox'
import ExcelJS from 'exceljs'
import saveAs from 'file-saver'
import { formatDate } from '@core/utils/filter'
import Pager from '@core/dev-extreme/utils/pager'
import GridBase from '@core/dev-extreme/mixins/grid/base'
import moment from 'moment'
import { confirm } from 'devextreme/ui/dialog'
import { Notify } from '@robustshell/utils/index'
import { ClientShipmentItemStatusEnum } from '~/enums'
import { shipmentItemsSource } from './shipmentItemsStore'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

const defaultImageUrl = require('@/assets/images/undraw/no_images.svg')

export default {
  components: {
    'light-box': CoolLightBox,
  },
  mixins: [GridBase],
  props: {
    batchId: {
      type: [String, Number],
      required: true,
    },
    batchName: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    dataSource: shipmentItemsSource,
    filters: {
      query: '',
    },
    itemId: null,
    selectedRows: [],
    shipDate: null,
    images: [],
    index: null,
    defaultImg: defaultImageUrl,
  }),
  computed: {
    dataGrid() {
      const dataGrid = this.$refs.shipmentItemsGridRef.instance
      return dataGrid
    },
    customTableHeight() {
      return window.innerHeight / 1.4
    },
  },
  mounted() {
    this.initialLoad()
  },
  destroyed() {
  },
  methods: {
    onCellDblClick(e) {
      if (e.rowType === 'data' && e.column.dataField === 'smallImage') {
        if (e.eventType === 'dxdblclick') {
          if (e.data.largeImage) {
            this.images.length = 0
            this.index = 0
            this.images.push(e.data.largeImage)
          }
        }
      }
    },
    initialLoad() {
      const filters = {
        companyId: 0,
        isUpc: false,
        status: 12,
        includeSortedItems: false,
        includeAllBatches: false,
        batchId: this.batchId,
        q: '',
      }
      const dataSource = this.dataGrid.getDataSource()
      dataSource.searchValue(filters)
      dataSource.load()
    },
    reload() {
      const filters = {
        companyId: 0,
        isUpc: false,
        status: 12,
        includeSortedItems: false,
        includeAllBatches: false,
        batchId: this.batchId,
        q: '',
      }
      const dataSource = this.dataGrid.getDataSource()
      dataSource.searchValue(filters)
      dataSource.reload()
    },
    onToolbarPreparing(e) {
      const toolbarItems = e.toolbarOptions.items
      const tempToolbarItems = [...toolbarItems]
      toolbarItems.splice(0, toolbarItems.length)
      toolbarItems.unshift({
        location: 'before',
        template: 'filterToolbar',
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          text: 'Submit Batch',
          type: 'default',
          stylingMode: 'contained',
          onClick: () => {
            this.onSubmitBatch()
          },
        },
        location: 'after',
      })
      toolbarItems.push({
        widget: 'dxDropDownButton',
        options: {
          keyExpr: 'id',
          displayExpr: 'name',
          text: 'Export',
          icon: 'exportxlsx',
          items: [
            {
              id: 'all',
              name: 'All Items',
              icon: 'selectall',
            },
            {
              id: 'selected',
              name: 'Selected',
              icon: 'exportselected',
            },
          ],
          width: 140,
          type: 'default',
          stylingMode: 'contained',
          hint: 'Download All Inventory',
          onItemClick: arg => {
            if (arg.itemData.id === 'all') {
              this.onDownloadInventory()
            } else if (arg.itemData.id === 'selected') {
              this.onDownloadSelectedInventory()
            }
          },
        },
        location: 'after',
      })
      tempToolbarItems.forEach(item => {
        toolbarItems.push(item)
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          hint: 'Reload Data',
          onClick: () => {
            this.dataGrid.refresh().then(() => {
              this.initialLoad()
            })
          },
        },
        location: 'after',
      })
    },
    async onDownloadInventory(e) {
      const filters = {
        companyId: 0,
        isUpc: false,
        status: 12,
        includeSortedItems: false,
        includeAllBatches: false,
        batchId: parseInt(this.batchId, 10),
        q: '',
      }
      const pager = new Pager({})
      const response = await shipService.getBySearchTerm(filters, pager.staticPageable)
      const data = response.data.content
      this.onDownloadExportData(data)
    },
    onDownloadSelectedInventory() {
      const data = this.dataGrid.getSelectedRowsData()
      if (Array.isArray(data) && data.length > 0) {
        this.onDownloadExportData(data)
      } else {
        Notify.warning('Please select at least one item to export data')
      }
    },
    onDownloadExportData(data) {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('Shipment Items')
      worksheet.columns = [
        { header: 'Batch Id', key: 'ShipmentId', width: 18 },
        { header: 'Title', key: 'Title', width: 80 },
        { header: 'Tracking', key: 'TrackingNumber', width: 14 },
        { header: 'Order Number', key: 'OrderNumber', width: 16 },
        { header: 'Ship Date', key: 'ShipmentDate', width: 12 },
        { header: 'QTY', key: 'Quantity', width: 8 },
        { header: 'C/B, MP', key: 'CountPerBundle', width: 8 },
        { header: 'UsedInBundle', key: 'CountInBundle', width: 8 },
        { header: 'Received', key: 'ReceivedCount', width: 8 },
        { header: 'Damaged', key: 'DamagedCount', width: 8 },
        { header: 'Shipped', key: 'ShippedCount', width: 8 },
        { header: 'Status', key: 'status', width: 8 },
        { header: 'ASIN', key: 'ASIN', width: 13 },
        { header: 'UPC', key: 'UPC', width: 14 },
        { header: 'FNSKU', key: 'FNSKU', width: 13 },
        { header: 'MSKU', key: 'MSKU', width: 18 },
        { header: 'Supplier', key: 'Supplier', width: 18 },
        { header: 'Action', key: 'itemAction', width: 12 },
        { header: 'Condition', key: 'Condition', width: 12 },
        { header: 'Fragile', key: 'Fragile', width: 8 },
        { header: 'PackType', key: 'PackType', width: 12 },
        { header: 'Cost', key: 'Cost', width: 12 },
        { header: 'SalePrice', key: 'SalePrice', width: 12 },
      ]
      worksheet.getRow(0).font = {
        size: 16,
        bold: true,
      }
      worksheet.getRow(0).alignment = {
        vertical: 'middle',
        horizontal: 'center',
      }
      worksheet.getRow(0).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'D59C9C' },
      }
      worksheet.autoFilter = 'A1:W1'
      worksheet.views = [{ state: 'frozen', xSplit: 1, ySplit: 1 }]

      const batchName = data.length > 0 ? data[0].batchName : 'Items'
      data.forEach(item => {
        worksheet.addRow([
          item.batchName,
          item.title,
          item.tracking,
          item.orderNumber,
          formatDate(item.shipmentDate),
          item.quantity,
          item.countPerBundle,
          item.countInBundle,
          item.receivedCount,
          item.damagedCount,
          item.shippedCount,
          item.status,
          item.asin,
          item.upc,
          item.fnsku,
          item.msku,
          item.supplier,
          item.itemAction,
          item.itemCondition,
          item.fragile,
          item.packType,
          item.itemCost,
          item.salePrice,
        ])
      })
      workbook.xlsx.writeBuffer().then(buffer => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          `${batchName}.xlsx`,
        )
      })
    },
    onOptionChanged(e) {
      if (e.fullName === 'searchPanel.text') {
        this.filters.query = ''
        this.filters.query = e.value
      }
    },
    onEditorPreparing(e) {
      if (e.parentType !== 'searchPanel') return
      e.editorOptions.onValueChanged = arg => {
        const query = arg.component.option('value')
        if (!query) {
          this.filters.query = query.trim()
          e.component.searchByText('')
          this.getBySearchTerm()
        }
      }
      e.editorOptions.onKeyDown = arg => {
        if (arg.event.keyCode === 13) {
          let query = arg.component.option('value')
          query = query.trim()
          arg.component.option('value', query)
          this.filters.query = query
          this.getBySearchTerm()
          e.component.searchByText(query)
        }
        if (arg.event.keyCode === 27) {
          e.component.searchByText('')
          arg.component.option('value', '')
          this.filters.query = ''
          this.getBySearchTerm()
        }
      }
    },
    resolveBundleMultiPackVariant(val) {
      return val > 0 ? 'badge-danger' : ''
    },
    resolveShipDateVariant(data) {
      if (data.data.status === ClientShipmentItemStatusEnum.delivered.key || data.data.status === ClientShipmentItemStatusEnum.fulfilled.key || data.data.status === ClientShipmentItemStatusEnum.shipped.key) return 'success'
      if (data.data.status === ClientShipmentItemStatusEnum.archived.key || data.data.status === ClientShipmentItemStatusEnum.intransit.key) return 'normal'
      if (data.data.status === ClientShipmentItemStatusEnum.blocked.key) return 'danger'
      const days = Math.ceil(
        moment.duration(moment().diff(moment(data.data.ShipmentDate))).asDays(),
      )
      if (days < 14) return 'success'
      return 'default'
    },
    getBySearchTerm() {
      const filters = {
        companyId: 0,
        isUpc: false,
        status: 12,
        includeSortedItems: false,
        includeAllBatches: false,
        batchId: parseInt(this.batchId, 10),
        q: this.filters.query,
      }
      const dataSource = this.dataGrid.getDataSource()
      dataSource.searchValue(filters)
      dataSource.reload()
    },
    editSelectedItem(e) {
      const data = e.row.data
      emitter.emit('on-open-add-item-batch', {
        data: data,
      })
    },
    deleteItem(e) {
      const confirmResult = confirm(
        "Are you sure you want to delete this Item record? You won't be able to revert this!",
        'Confirmation',
      )
      confirmResult.then(dialogResult => {
        if (dialogResult) {
          shipService.deleteItemById(e.row.key).then(() => {}).then(() => {
            this.reload()
          })
        }
      })
    },
    onSubmitBatch(e) {
      const dataSource = this.dataGrid.getDataSource()
      const batchId = parseInt(this.batchId, 10)
      const itemCount = dataSource.items()
      if (itemCount.length === 0) {
        Notify.warning('This batch is empty. Please add item before submit to this batch.')
      }
      shipService.submitDraftBatch(batchId).then(() => {
        this.$router.push({ name: 'uploaded-batches' })
      })
    },
  },
}
</script>

<style lang="scss">
#shipmentItemsGrid {
  .dx-toolbar {
    border-top:1px solid #3c4254;
    border-left:1px solid #3c4254;
    border-right:1px solid #3c4254;
    margin-bottom: 0px !important;
  }
  .dx-toolbar-before {
    align-items: center;
  }
}
</style>
