<template>
  <div>
    <div ref="card-search-main" class="card">
      <div class="card-content">
        <div class="card-body">
          <div class="row">
            <div class="col-xl-6">
              <dx-util-text-box
                v-model.trim="query"
                placeholder="Search Item"
                mode="search"
                @enter-key="getSearchByQuery"
              >
                <dx-text-box-button
                  location="after"
                  name="queryTextBoxButton"
                  :options="queryTextBoxButtonOptions"
                />
              </dx-util-text-box>
            </div>
            <div class="col-xl-1 offset-xl-3 py-half px-1">
              <dx-util-button
                  hint="Return to Shipping Plans"
                  text="Cancel"
                  :visible="!isShownShipmentItems"
                  class="mr-half align-self-center w-100"
                  type="danger"
                  height="42"
                  @click="onClickReturnToShippingPlans"
                />
              </div>
            <div class="col-xl-2 py-half px-1">
              <dx-util-button
                  hint="Add New Product"
                  text="Add New Product"
                  class="mr-half align-self-center w-100"
                  type="success"
                  height="42"
                  @click="onClickAddNewToBatch"
                />
            </div>
            <!-- #region Shipment Items Data Grid -->
            <div v-show="isShownShipmentItems" class="col-xl-12 pt-1">
              <shipment-items v-if="isShownShipmentItems" :batch-id="batchId" :batch-name=" batchInfo.batchName" />
            </div>
            <!-- #endregion -->
            <div v-show="!isShownShipmentItems" class="col-xl-12 pt-1">
              <dx-data-grid
                id="searchResultDataGrid"
                ref="searchResultDataGridRef"
                key-expr="id"
                :data-source="searchResultData"
                :height="customTableHeight"
                :allow-column-reordering="true"
                :allow-column-resizing="true"
                :column-auto-width="true"
                :show-column-lines="true"
                :show-row-lines="true"
                :show-borders="true"
                :row-alternation-enabled="false"
                :hover-state-enabled="true"
                :customize-columns="customizeColumns"
                @initialized="onInitialized"
                @content-ready="onContentReady"
                @toolbar-preparing="onToolbarPreparing($event)"
              >
                <dx-search-panel :visible="true" :width="200" placeholder="Search..." />
                <dx-paging :page-size="pagerOptions.pageSize" />
                <dx-pager
                  :allowed-page-sizes="pagerOptions.allowedPageSizes"
                  :show-info="pagerOptions.showInfo"
                  :show-navigation-buttons="pagerOptions.showNavigationButtons"
                  :show-page-size-selector="pagerOptions.showPageSizeSelector"
                  :visible="pagerOptions.visible"
                />
                <dx-column type="buttons" caption="Actions" :width="120">
                  <dx-button
                    ref="btnAddToBatch"
                    text="Select"
                    css-class="btn btn-primary"
                    :on-click="onClickAddToBatch"
                  />
                </dx-column>
                <dx-column data-field="imageLink" cell-template="imageCellTemplate" caption="Image" alignment="center" :allow-sorting="false" />
                <template #imageCellTemplate="{data}">
                  <b-img-lazy :rounded="true" width="72px" height="72px" :src="data.value || defaultImg" />
                </template>
                <dx-column data-field="ProductTitle" cell-template="titleCellTemplate" :min-width="300" />
                <template #titleCellTemplate="{data}">
                  <div class="dx-product-title">
                    {{ data.value }}
                  </div>
                </template>
                <dx-column data-field="amzRank" />
                <dx-column data-field="ASIN" caption="ASIN" :width="120" cell-template="asinCellTemplate" />
                <template #asinCellTemplate="{data}">
                  <a target="_blank" :href="`https://www.amazon.com/gp/product/${data.value}`">
                    {{ data.value }}
                  </a>
                </template>
                <dx-column data-field="UPC" />
                <dx-column data-field="productType" />
                <dx-column data-field="ManufactureOrSupplier" />
                <dx-column data-field="dimHeight" caption="Height" />
                <dx-column data-field="dimWidth" caption="Width" />
                <dx-column data-field="dimLength" caption="Length" />
                <dx-column data-field="weight" caption="Weight" />
                <template #filterToolbar>
                  <div class="d-flex flex-row align-items-center">
                    <h5 class="my-0 mx-1">
                      Batch Name: <b class="text-warning"> {{ batchInfo.batchName }} </b>
                    </h5>
                  </div>
                </template>
              </dx-data-grid>
            </div>
            <div class="col-xl-12">
              <add-item-form @on-close-add-item-batch="onCloseAddItemBatch" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { emitter } from '@/libs/bus'
import store from '@/store'
import Filters from '@robustshell/mixins/filters'
import GridBase from '@core/dev-extreme/mixins/grid/base'
import { DxButton as DxTextBoxButton } from 'devextreme-vue/text-box'
import productsService from '@/http/requests/product/productsService'
import ProductFilter from '@/http/models/search-filters/ProductFilter'
import ItemActionTypeEnum from '@/enums/identifierst-type.enum'
import {
  isValidAsin,
  isValidUpc,
} from '@/views/apps/ship/validationItem'
import AddItemForm from './components/AddItemForm.vue'
import ShipmentItems from './components/ShipmentItems.vue'

const defaultImageUrl = require('@/assets/images/undraw/no_images.svg')

export default {
  components: {
    DxTextBoxButton,
    ShipmentItems,
    AddItemForm,
  },
  mixins: [GridBase, Filters],
  data() {
    return {
      query: '',
      accountNo: '',
      isShownShipmentItems: true,
      batchInfo: {},
      searchResultData: [],
      defaultImg: defaultImageUrl,
    }
  },
  computed: {
    queryTextBoxButtonOptions() {
      return {
        text: 'Search',
        type: 'default',
        onClick: () => {
          this.query = this.query.trim()
          this.searchByQuery()
        },
      }
    },
    customTableHeight() {
      return window.innerHeight / 1.4
    },
  },
  created() {
    this.batchInfo = { ...store.getters['appLocal/getUploadedShipmentItemsParams'] }
    const userData = JSON.parse(localStorage.getItem('userData'))
    this.accountNo = userData.accountNo
    this.getStores(userData.accountNo)
    this.batchId = this.$route.params.batchId
    this.getWarehouses()
  },
  methods: {
    onToolbarPreparing(e) {
      const toolbarItems = e.toolbarOptions.items
      toolbarItems.unshift({
        location: 'before',
        template: 'filterToolbar',
      })
    },
    getSearchByQuery(e) {
      if (e.event && e.event.keyCode === 13) {
        this.query = this.query.trim()
        this.searchByQuery()
      }
    },
    async searchByQuery() {
      ProductFilter.setDefaultFilters()
      let identifiersType = ''
      if (isValidAsin(this.query)) {
        identifiersType = ItemActionTypeEnum.ASIN.value
      } else if (isValidUpc(this.query)) {
        identifiersType = ItemActionTypeEnum.UPC.value
      } else {
        identifiersType = ItemActionTypeEnum.KEYWORDS.value
      }

      ProductFilter.accountNo = this.accountNo
      ProductFilter.q = this.query
      ProductFilter.identifiersType = identifiersType
      const filters = ProductFilter.getFilters()
      filters.companyId = this.batchInfo.companyId
      if (!this.query || !filters.q) {
        this.searchResultData.length = 0
        return
      }
      const localProducts = await productsService.getByQueryForWorkingBatch(filters)
      const localProductData = [...localProducts.data]
      if (localProductData.length > 0) {
        this.normalizeData(localProductData)
        this.$nextTick(() => {
          this.isShownShipmentItems = false
        })
      }
    },
    normalizeData(data) {
      this.searchResultData.length = 0
      data.forEach(item => {
        const product = {
          id: null,
          batchId: this.batchId,
          imageLink: item.smallImage,
          TrackingNumber: '',
          OrderNumber: '',
          OrderNumberSold: '',
          PalletId: '',
          ShipmentDate: null,
          ExpirationDate: null,
          ASIN: item.asin,
          MSKU: '',
          UPC: item.upc,
          FNSKU: '',
          SerialNo: '',
          ProductTitle: item.title,
          productType: item.productType,
          amzRank: item.amzRank,
          ManufactureOrSupplier: item.manufacturer,
          dimHeight: item.dimHeight,
          dimLength: item.dimLength,
          dimWidth: item.dimWidth,
          weight: item.weight,
          NumberOfUnits: 0,
          Condition: 'NEW',
          Cost: 0,
          SalePrice: 0,
          Fragile: 'NO',
          MultiPack: 'NO',
          Bundle: 'NO',
          CountPerBundle: 0,
          Action: '',
          Notes: '',
        }
        this.searchResultData.push(product)
      })
    },
    onClickAddToBatch(e) {
      const data = e.row.data
      emitter.emit('on-open-add-item-batch', {
        data: data,
        batchId: this.batchId,
      })
    },
    onClickAddNewToBatch(e) {
      emitter.emit('on-open-add-item-batch', {
        data: null,
        batchId: this.batchId,
      })
    },
    onCloseAddItemBatch() {
      this.isShownShipmentItems = false
      this.$nextTick(() => {
        this.isShownShipmentItems = true
      })
    },
    onClickReturnToShippingPlans() {
      this.isShownShipmentItems = true
      this.query = ''
    },
  },
}
</script>

<style lang="scss">
  #searchResultDataGrid {
    .dx-toolbar {
      border-top:1px solid #3c4254;
      border-left:1px solid #3c4254;
      border-right:1px solid #3c4254;
      margin-bottom: 0px !important;
    }
    .dx-toolbar-before {
      align-items: center;
    }
  }
  </style>
